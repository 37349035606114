import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, Form, Input, message, Switch } from 'antd';
import ConfigurationService from '../../api/ConfigurationService';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

const ParametersPage = () => {
    const [parameters, setParameters] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [editParameter, setEditParameter] = useState(null);
    const [form] = Form.useForm();
    const [passwordVisible, setPasswordVisible] = React.useState(false);

    const configurationService = new ConfigurationService();
    useEffect(() => {
        fetchParameters()
    }, []);

    const fetchParameters = async () => {
        try {
            setLoading(true);
            const parameterData = await configurationService.getParameters({});
            setParameters(parameterData.model);
            setLoading(false);
        } catch (error) {
            console.error('Parametre verileri alınamadı:', error.message);
            setLoading(false);
        }
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Key',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Durum',
            dataIndex: 'statusID',
            key: 'statusID',
            render : (text, record) => (<span style={record.statusID == 1 ? {color : 'green' , fontWeight : 'bold'} : {color : 'black' , fontWeight : 'bold'}}>{record.statusID == 1 ? 'Aktif' : 'Pasif'}</span>)
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => handleEdit(record)}><EditOutlined /></a>
                    <a onClick={() => showDeleteConfirm(record.id)}><DeleteOutlined /></a>
                </Space>
            ),
        },
    ];
    const showModal = () => {
        form.resetFields();
        setVisible(true);
    };
    const handleEdit = (parameter) => {
        setEditParameter(parameter);
        setVisible(true);
    };
    const handleOk = async (values) => {
        try {
            if (editParameter) {
                values.id = editParameter.id;
                await configurationService.updateParameter(values);
                message.success('Parametre güncellendi.');
                await fetchParameters();
                setVisible(false);
            }
            else {
                await configurationService.insertParameter(values);
                await fetchParameters();
                message.success('Yeni parametre oluşturuldu.');
                setVisible(false);
            }
        } catch (error) {
            console.error('Yeni parametre oluşturulamadı:', error.message);
            message.error('Yeni parametre oluşturulamadı.');
        }
    };
    const handleCancel = () => {
        setVisible(false);
        setEditParameter(null);
        form.resetFields();
    };

    const showDeleteConfirm = (parameterId) => {
        Modal.confirm({
            title: 'Parametreyı sil',
            content: 'Parametreyı silmek istediğinizden emin misiniz?',
            okText: 'Sil',
            okType: 'danger',
            cancelText: 'İptal',
            onOk() {
                handleDelete(parameterId);
            },
        });
    };
    const handleDelete = async (parameterId) => {
        try {
            await configurationService.deleteParameter(parameterId);
            message.success('Parametre silindi.');
            await fetchParameters();
        } catch (error) {
            console.error('Parametre silinemedi:', error.message);
            message.error('Parametre silinemedi.');
        }
    };
    return (
        <div>
            <Button type="primary" style={{ marginBottom: 16 }} onClick={showModal}>
                Yeni Parametre Oluştur
            </Button>
            <Table
                columns={columns}
                dataSource={parameters}
                loading={loading}
                rowKey="id"
                bordered
                pagination={{ pageSize: 10 }}
            />
            <Modal
                title={editParameter ? 'Parametreyı Düzenle' : 'Yeni Parametre Oluştur'}
                open={visible}
                onCancel={handleCancel}
                onOk={form.submit}
                okText={editParameter ? 'Güncelle' : 'Oluştur'}
                cancelText="İptal"
                destroyOnClose={true}
            >
                <Form
                    name="newParameterForm"
                    onFinish={handleOk}
                    onFinishFailed={(errorInfo) => console.log('Failed:', errorInfo)}
                    initialValues={editParameter}
                    form={form}
                    preserve={false}
                >
                    <Form.Item
                        label="Key"
                        name="key"
                        rules={[{ required: true, message: 'Lütfen key girin.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Value"
                        name="value"
                        rules={[{ required: true, message: 'Lütfen value girin.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="StatusID"
                        name="statusID"
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="Aktif"
                            unCheckedChildren="Pasif"
                            onChange={(checked) => form.setFieldsValue({ statusID: checked ? 1 : 0 })}
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ParametersPage;

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import { Link } from 'react-router-dom/dist';
import { StepBackwardOutlined } from '@ant-design/icons';
import MenuDetail from './MenuDetail';
import MenuItems from './MenuItems';

const { TabPane } = Tabs;

const MenuTabs = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState('1');

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <div>
            <Link to="/menus"><StepBackwardOutlined style={{ fontSize: '48px' }} /></Link>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="Menu Detayı" key="1">
                    <MenuDetail />
                </TabPane>
                <TabPane tab="Menu Items" key="2">
                    <MenuItems />
                </TabPane>
            </Tabs>
        </div>

    );
};

export default MenuTabs;

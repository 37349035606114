import { createBrowserRouter } from "react-router-dom";
import Users from "./pages/Users"
import Dashboard from "./pages/Dashboard"
import ProtectedRoute from "./ProtectedRoute";
import MainLayout from "./pages/MainLayout";
import LoginPage from "./pages/Login";

const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <LoginPage />,
            children: [
                {
                    element: <ProtectedRoute />,
                    children: [
                        {
                            path: "/users",
                            element: <Users />
                        },
                        {
                            path: "/dashboard",
                            element: <Dashboard />
                        },
                    ]
                },
                {
                    path: "*",
                    element: <p>404 Error - Nothing here...</p>
                }
            ]
        }
    ]
);

export default router
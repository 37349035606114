import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import MainLayout from '../pages/MainLayout';

const PrivateRoute = () => {
    const token = localStorage.getItem('token'); // determine if authorized, from context or however you're doing it

    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return token ? <MainLayout><Outlet /></MainLayout> : <Navigate to="/login" />;
}
export default PrivateRoute;
import React, { useEffect, useState } from 'react';
import ContentService from '../../../api/ContentService';
import { message } from 'antd';
import { useParams } from 'react-router-dom/dist';

const PageDetail = () => {
    const [page, setPage] = useState(null);
    const contentService = new ContentService();
    const { id } = useParams();
    useEffect(() => {
        fetchPage();
    }, [])

    const fetchPage = async () => {
        const resp = await contentService.getPage(id);
        if (resp.model) {
            setPage(resp.model);
        }
        else {
            message.error("Sayfa detayı çekilemedi");
        }
    }

    if (!page) {
        return <p>Sayfa bulunamadı.</p>;
    }

    return (
        <div>
            <h2>Sayfa Detayları</h2>
            <p><strong>Sayfa Adı:</strong> {page.name}</p>
            <p><strong>Sayfa Kodu:</strong> {page.code}</p>
        </div>
    );
};

export default PageDetail;
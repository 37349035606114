import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, Form, Input, message } from 'antd';
import ContentService from '../../../api/ContentService';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom/dist';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const MenuItems = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [editorHtml, setEditorHtml] = useState('');
  const [editMenuItem, setEditMenuItem] = useState(null);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const contentService = new ContentService();
  const { id } = useParams();

  console.log('id is : ', id);
  useEffect(() => {
    fetchMenuItems()
  }, []);
  const fetchMenuItems = async () => {
    try {
      setLoading(true);
      const menuItemData = await contentService.getMenuItems(id);
      setMenuItems(menuItemData.model);
      setLoading(false);
    } catch (error) {
      console.error('Menu Item verileri alınamadı:', error.message);
      setLoading(false);
    }
  };
  const handleEditorChange = (newState) => {
    setEditorHtml(newState);
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Kod',
      dataIndex: 'code',
      key: 'code',
      render: (text, record) => <span onClick={() => navigate(`/menuDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
    },
    {
      title: 'Adı',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Link',
      dataIndex: 'to',
      key: 'to',
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => handleEdit(record)}><EditOutlined /></a>
          <a onClick={() => showDeleteConfirm(record.id)}><DeleteOutlined /></a>
        </Space>
      ),
    },
  ];
  const showModal = () => {
    form.resetFields();
    setVisible(true);
  };
  const handleEdit = (site) => {
    setEditMenuItem(site);
    setVisible(true);
  };
  const handleOk = async (values) => {
    try {
      values.menuID = id;
      if (editMenuItem) {
        values.id = editMenuItem.id;
        await contentService.updateMenuItem(values);
        message.success('Menu Item güncellendi.');
        await fetchMenuItems();
        setVisible(false);
      }
      else {
        await contentService.insertMenuItem(values);
        await fetchMenuItems();
        message.success('Yeni menu item oluşturuldu.');
        setVisible(false);
      }
    } catch (error) {
      message.error('Yeni menu item oluşturulamadı.');
    }
  };
  const handleCancel = () => {
    setVisible(false);
    setEditMenuItem(null);
    form.resetFields();
  };

  const showDeleteConfirm = (siteId) => {
    Modal.confirm({
      title: 'Menu Item sil',
      content: 'Menu Item silmek istediğinizden emin misiniz?',
      okText: 'Sil',
      okType: 'danger',
      cancelText: 'İptal',
      onOk() {
        handleDelete(siteId);
      },
    });
  };
  const handleDelete = async (menuItemId) => {
    try {
      await contentService.deleteMenuItem(menuItemId);
      message.success('Menu Item silindi.');
      await fetchMenuItems();
    } catch (error) {
      message.error('Menu Item silinemedi.');
    }
  };
  return (
    <div>
      <Button type="primary" style={{ marginBottom: 16 }} onClick={showModal}>
        Yeni Menu Item Oluştur
      </Button>
      <Table
        columns={columns}
        dataSource={menuItems}
        loading={loading}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title={editMenuItem ? 'Menu Item Düzenle' : 'Yeni Menu Item Oluştur'}
        open={visible}
        onCancel={handleCancel}
        onOk={form.submit}
        okText={editMenuItem ? 'Güncelle' : 'Oluştur'}
        cancelText="İptal"
        destroyOnClose={true}
        width="65%" 
        maxWidth="80%"
      >
        <Form
          name="newMenuItemForm"
          onFinish={handleOk}
          onFinishFailed={(errorInfo) => console.log('Failed:', errorInfo)}
          initialValues={editMenuItem}
          form={form}
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="Adı"
            name="name"
            rules={[{ required: true, message: 'Lütfen menu item adını girin.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Kod"
            name="code"
            rules={[{ required: true, message: 'Lütfen menu item kodunu girin.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="To"
            name="to"
            rules={[{ required: true, message: 'Lütfen menu item linkini girin.' }]}
          >
            <Input />
          </Form.Item>
          
        </Form>
      </Modal>
    </div>
  );
};

export default MenuItems;

import React, { useState } from 'react';
import { Tabs } from 'antd';
import { Link } from 'react-router-dom/dist';
import { StepBackwardOutlined } from '@ant-design/icons';
import PageDetail from './PageDetail';
import PageWidgets from './PageWidgets';

const { TabPane } = Tabs;

const PageTabs = () => {
    const [activeTab, setActiveTab] = useState('1');

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <div>
            <Link to="/pages"><StepBackwardOutlined style={{ fontSize: '48px' }} /></Link>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="Sayfa Detayı" key="1">
                    <PageDetail />
                </TabPane>
                <TabPane tab="Sayfa Widgetları" key="2">
                    <PageWidgets />
                </TabPane>
            </Tabs>
        </div>

    );
};

export default PageTabs;

import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, NotificationOutlined, BookOutlined , MenuOutlined , PicCenterOutlined , HomeOutlined , SettingOutlined , AccountBookOutlined } from '@ant-design/icons';
import LogoutButton from '../components/Logout';

const { Header, Content, Sider } = Layout;

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const onCollapse = collapsed => {
    setCollapsed(collapsed);
  };
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <div style={{ position: 'absolute', top: 10, right: 10 }}>
        <LogoutButton />
      </div>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        {!collapsed ? (
          <div style={{ textAlign: 'center', padding: '24px 0' }}>
            <p style={{ fontFamily: 'Roboto', fontSize: '32px', fontWeight: 'bold', color: '#fff', margin: 0 }}>Tec10</p>
            <p style={{ fontFamily: 'Roboto', fontSize: '24px', fontWeight: 'bold', color: '#fff', margin: 0 }}>Console</p>
          </div>
        ) : null}

        <div className="logo" />
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['0']}>
          <Menu.Item key="0" icon={<HomeOutlined />}>
            <Link to="/">Ana Sayfa</Link>
          </Menu.Item>
          <Menu.Item key="1" icon={<UserOutlined />}>
            <Link to="/users">Kullanıcılar</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<NotificationOutlined />}>
            <Link to="/sites">Siteler</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<BookOutlined />}>
            <Link to="/pages">Sayfalar</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<PicCenterOutlined />}>
            <Link to="/widgets">Widgetlar</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<MenuOutlined />}>
            <Link to="/menus">Menüler</Link>
          </Menu.Item>
          <Menu.Item key="6" icon={<AccountBookOutlined />}>
            <Link to="/blogposts">Blog Yazıları</Link>
          </Menu.Item>
          <Menu.Item key="7" icon={<SettingOutlined />}>
            <Link to="/parameters">Parametreler</Link>
          </Menu.Item>
          {/* Diğer menü elemanları */}
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{ padding: 0 }} />
        <Content style={{ margin: '0 16px' }}>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            {children}
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;

import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, Form, Input, message } from 'antd';
import MembershipService from '../api/MembershipService';
import { DeleteOutlined , EditOutlined} from '@ant-design/icons';

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [editUser, setEditUser] = useState(null);
    const [form] = Form.useForm();
    const [passwordVisible, setPasswordVisible] = React.useState(false);

    const membershipService = new MembershipService();
    useEffect(() => {
        fetchUsers()
    }, []);

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const userData = await membershipService.getUsers({});
            setUsers(userData.model);
            setLoading(false);
        } catch (error) {
            console.error('Kullanıcı verileri alınamadı:', error.message);
            setLoading(false);
        }
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'E-posta',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Oluşturma Tarihi',
            dataIndex: 'createdDate',
            key: 'createdDate',
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => handleEdit(record)}><EditOutlined /></a>
                    <a onClick={() => showDeleteConfirm(record.id)}><DeleteOutlined /></a>
                </Space>
            ),
        },
    ];
    const showModal = () => {
        form.resetFields();
        setVisible(true);
    };
    const handleEdit = (user) => {
        setEditUser(user);
        setVisible(true);
    };
    const handleOk = async (values) => {
        try {
            if (editUser) {
                values.id = editUser.id;
                await membershipService.updateUser(values);
                message.success('Kullanıcı güncellendi.');
                await fetchUsers();
                setVisible(false);
            }
            else {
                await membershipService.insertUser(values);
                await fetchUsers();
                message.success('Yeni kullanıcı oluşturuldu.');
                setVisible(false);
            }
        } catch (error) {
            console.error('Yeni kullanıcı oluşturulamadı:', error.message);
            message.error('Yeni kullanıcı oluşturulamadı.');
        }
    };
    const handleCancel = () => {
        setVisible(false);
        setEditUser(null);
        form.resetFields();
    };

    const showDeleteConfirm = (userId) => {
        Modal.confirm({
            title: 'Kullanıcıyı sil',
            content: 'Kullanıcıyı silmek istediğinizden emin misiniz?',
            okText: 'Sil',
            okType: 'danger',
            cancelText: 'İptal',
            onOk() {
                handleDelete(userId);
            },
        });
    };
    const handleDelete = async (userId) => {
        try {
            await membershipService.deleteUser(userId);
            message.success('Kullanıcı silindi.');
            await fetchUsers();
        } catch (error) {
            console.error('Kullanıcı silinemedi:', error.message);
            message.error('Kullanıcı silinemedi.');
        }
    };
    return (
        <div>
            <Button type="primary" style={{ marginBottom: 16 }} onClick={showModal}>
                Yeni Kullanıcı Oluştur
            </Button>
            <Table
                columns={columns}
                dataSource={users}
                loading={loading}
                rowKey="id"
                bordered
                pagination={{ pageSize: 10 }}
            />
            <Modal
                title={editUser ? 'Kullanıcıyı Düzenle' : 'Yeni Kullanıcı Oluştur'}
                open={visible}
                onCancel={handleCancel}
                onOk={form.submit}
                okText={editUser ? 'Güncelle' : 'Oluştur'}
                cancelText="İptal"
                destroyOnClose={true}
            >
                <Form
                    name="newUserForm"
                    onFinish={handleOk}
                    onFinishFailed={(errorInfo) => console.log('Failed:', errorInfo)}
                    initialValues={editUser}
                    form={form}
                    preserve={false}
                >
                    <Form.Item
                        label="Ad"
                        name="name"
                        rules={[{ required: true, message: 'Lütfen adınızı girin.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Soyad"
                        name="surname"
                        rules={[{ required: true, message: 'Lütfen soyadınızı girin.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            { required: true, message: 'Lütfen e-posta adresinizi girin.' },
                            { type: 'email', message: 'Lütfen geçerli bir e-posta adresi girin.' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Parola"
                        name="password"
                    >
                        <Input.Password id='userFormPassword' visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
                        />
                    </Form.Item>
                    {/* <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Oluştur
                        </Button>
                    </Form.Item> */}
                </Form>
            </Modal>
        </div>
    );
};

export default UsersPage;

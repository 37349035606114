import { Button, Form, Input, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import ContentService from '../../../api/ContentService';
import { Link, useNavigate, useParams } from 'react-router-dom/dist';
import { useForm } from 'antd/es/form/Form';
import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const WidgetDetail = () => {
    const [widget, setWidget] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [editorHtml, setEditorHtml] = useState('');

    const contentService = new ContentService();
    const { id } = useParams();
    const [form] = useForm();
    const { TextArea } = Input;
    const navigate = useNavigate();
    const fetchWidget = async () => {
        try {
            setLoading(true);
            const widgetData = await contentService.getWidget(id);
            setWidget(widgetData.model);
            form.setFieldsValue(widgetData.model);
            setLoading(false);
        } catch (error) {
            console.error('Widget verisi alınamadı:', error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id > 0)
            fetchWidget();
    }, [])

    const handleFileChange = (info) => {
        let fileList = [...info.fileList];
        // Dosya yüklemesi sırasında dosya durumlarını kontrol etmek için gerekirse burada işlemler yapılabilir.
        setFileList(fileList);
    };
    const handleEditorChange = (newState) => {
        setEditorHtml(newState);
    };
    const uploadProps = {
        beforeUpload: () => false,
        fileList,
        onChange: handleFileChange,
    };
    const handleOk = async (values) => {
        try {
            if (widget) {
                values.id = widget.id;
                const formData = new FormData();
                Object.keys(values).forEach((key) => formData.append(key, values[key]));
                if (fileList.length > 0) {
                    fileList.forEach((file) => {
                        formData.append('files', file.originFileObj);
                    });
                }
                await contentService.updateWidget(formData);
                message.success('Widget güncellendi.');
                await fetchWidget({ id });
            } else {
                const formData = new FormData();
                Object.keys(values).forEach((key) => formData.append(key, values[key]));
                if (fileList.length > 0) {
                    fileList.forEach((file) => {
                        formData.append('files', file.originFileObj);
                    });
                }
                var resp = await contentService.insertWidget(formData);
                await fetchWidget(resp.model);
                message.success('Yeni widget oluşturuldu.');
                navigate('/widgets');
            }
        } catch (error) {
            message.error('Widget işlemleri sırasında bir hata oluştu.');
            console.error(error);
        }
    };

    return (
        <Form
            name="widgetForm"
            onFinish={handleOk}
            onFinishFailed={(errorInfo) => console.log('Failed:', errorInfo)}
            initialValues={widget}
            form={form}
            preserve={false}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 24 }}
            labelAlign='left'
        >
            <Form.Item
                label="Ad"
                name="name"
                rules={[{ required: true, message: 'Lütfen widget adını girin.' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Kod"
                name="code"
                rules={[{ required: true, message: 'Lütfen widget kodunu girin.' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Başlık"
                name="title"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Alt Başlık"
                name="subTitle"
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="BackgroundColor"
                name="backgroundColor"
            >
                <Input />
            </Form.Item>
            <Form.Item label="Description" name="description">
                <ReactQuill
                    theme="snow"
                    value={editorHtml}
                    onChange={handleEditorChange}
                />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 18 }} label="ImagePath">
                <div style={{ display: 'flex' }}>
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Dosya Seç</Button>
                    </Upload>
                    {widget && widget.imagePath ?
                        <div style={{ marginLeft: '16px' }}>
                            <Link to={widget.imagePath} target='_blank'>{widget.imagePath.substr(widget.imagePath.lastIndexOf("\\") + 1)}</Link> </div> : null}
                </div>
            </Form.Item>
            <Form.Item
                wrapperCol={{ offset: 20, span: 18 }} // Submit butonunu sola yasla
            >
                <Button type="primary" htmlType="submit">Kaydet</Button>
            </Form.Item>
        </Form>
    )
}

export default WidgetDetail;
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tabs } from 'antd';
import SiteDetail from './SiteDetail';
import SitePages from './SitePages'; // Sitepages bileşeninin import edildiği yer
import { Link } from 'react-router-dom/dist';
import { StepBackwardOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

const SiteTabs = () => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState('1');

    const handleTabChange = (key) => {
        setActiveTab(key);
    };

    return (
        <div>
            <Link to="/sites"><StepBackwardOutlined style={{ fontSize: '48px' }} /></Link>
            <Tabs activeKey={activeTab} onChange={handleTabChange}>
                <TabPane tab="Site Detayı" key="1">
                    <SiteDetail />
                </TabPane>
                <TabPane tab="Site Sayfaları" key="2">
                    <SitePages />
                </TabPane>
            </Tabs>
        </div>

    );
};

export default SiteTabs;

import BaseService from "./BaseService";

export default class MembershipService extends BaseService {
  constructor() {
    super();
    this.schemaName = "Membership";
  }
  login(entity) {
    return this.postObject(this.schemaName, "Login", entity);
  }
  updateUserPassword(entity) {
    return this.postObject(this.schemaName, "UpdateUserPassword", entity);
  }
  logout(entity) {
    return this.postObject(this.schemaName, "Logout", entity);
  }
  getUsers(entity) {
    return this.postObject(this.schemaName, "GetUsers", entity);
  }
  insertUser(entity) {
    return this.postObject(this.schemaName, "InsertUser", entity);
  }
  deleteUser(entity) {
    return this.deleteObject(this.schemaName, "DeleteUser", entity);
  }
  updateUser(entity) {
    return this.postObject(this.schemaName, "UpdateUser", entity);
  }
}

import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, Form, Input, message, Select } from 'antd';
import ContentService from '../../../api/ContentService';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom/dist';
import { Option } from 'antd/es/mentions';

const Sites = () => {
    const [sites, setSites] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [editSite, setEditSite] = useState(null);
    const [selectedSite, setSelectedSite] = useState(null);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const contentService = new ContentService();
    useEffect(() => {
        fetchSites()
    }, []);
    const handleSiteChange = (value) => {
        setSelectedSite(value);
    };

    const fetchSites = async () => {
        try {
            setLoading(true);
            const siteData = await contentService.getSites({});
            setSites(siteData.model);
            setLoading(false);
        } catch (error) {
            console.error('Site verileri alınamadı:', error.message);
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <span onClick={() => navigate(`/siteDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <span onClick={() => navigate(`/siteDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'Kod',
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => <span onClick={() => navigate(`/siteDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'Domain',
            dataIndex: 'webAddress',
            key: 'webAddress',
            render: (text, record) => <a href={text.includes('http') ? text : `http://${text}`} target='_blank' style={{ cursor: 'pointer' }}>{text}</a>,
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => handleEdit(record)}><EditOutlined /></a>
                    <a onClick={() => showDeleteConfirm(record.id)}><DeleteOutlined /></a>
                </Space>
            ),
        },
    ];
    const showModal = () => {
        form.resetFields();
        setVisible(true);
    };
    const handleEdit = (site) => {
        setEditSite(site);
        setVisible(true);
    };
    const handleOk = async (values) => {
        try {
            if (editSite) {
                values.id = editSite.id;
                await contentService.updateSite(values);
                message.success('Site güncellendi.');
                await fetchSites();
                setVisible(false);
            }
            else {
                await contentService.insertSite(values);
                await fetchSites();
                message.success('Yeni site oluşturuldu.');
                setVisible(false);
            }
        } catch (error) {
            console.error('Yeni site oluşturulamadı:', error.message);
            message.error('Yeni site oluşturulamadı.');
        }
    };
    const handleCancel = () => {
        setVisible(false);
        setEditSite(null);
        form.resetFields();
    };

    const showDeleteConfirm = (siteId) => {
        Modal.confirm({
            title: 'Siteyi sil',
            content: 'Siteyi silmek istediğinizden emin misiniz?',
            okText: 'Sil',
            okType: 'danger',
            cancelText: 'İptal',
            onOk() {
                handleDelete(siteId);
            },
        });
    };
    const handleDelete = async (siteId) => {
        try {
            await contentService.deleteSite(siteId);
            message.success('Site silindi.');
            await fetchSites();
        } catch (error) {
            console.error('Site silinemedi:', error.message);
            message.error('Site silinemedi.');
        }
    };
    return (
        <div>
            <Button type="primary" style={{ marginBottom: 16 }} onClick={showModal}>
                Yeni Site Oluştur
            </Button>
            <Table
                columns={columns}
                dataSource={sites}
                loading={loading}
                rowKey="id"
                bordered
                pagination={{ pageSize: 10 }}
            />
            <Modal
                title={editSite ? 'Siteyi Düzenle' : 'Yeni Site Oluştur'}
                open={visible}
                onCancel={handleCancel}
                onOk={form.submit}
                okText={editSite ? 'Güncelle' : 'Oluştur'}
                cancelText="İptal"
                destroyOnClose={true}
                width="50%"
                maxWidth="65%"
            >
                <Form
                    name="newSiteForm"
                    onFinish={handleOk}
                    onFinishFailed={(errorInfo) => console.log('Failed:', errorInfo)}
                    initialValues={editSite}
                    form={form}
                    preserve={false}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 16 }}
                >
                    <Form.Item
                        label="Ad"
                        name="name"
                        rules={[{ required: true, message: 'Lütfen site adını girin.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Kod"
                        name="code"
                        rules={[{ required: true, message: 'Lütfen site kodunu girin.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Domain"
                        name="webAddress"
                        rules={[
                            { required: true, message: 'Lütfen domain adresini girin.' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    {!editSite &&
                        <Form.Item
                            label="Klonla"
                            name="CloneID"
                        >
                            <Select onChange={handleSiteChange} value={selectedSite} style={{ width: '100%' }} >
                                {sites.map((site) => (
                                    <Option key={site.id} value={site.id}>
                                        {site.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    }

                </Form>
            </Modal>
        </div>
    );
};

export default Sites;

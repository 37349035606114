import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Typography } from 'antd';
import ConfigurationService from '../api/ConfigurationService';
const { Title, Text } = Typography;;

const DashboardPage = () => {
  const [statistics, setStatistics] = useState({});
  const configurationService = new ConfigurationService();

  useEffect(() => {
    const fetchDashboardStatistics = async () => {
      try {
        const response = await configurationService.getDashboardStatistics();
        setStatistics(response.model);
      } catch (error) {
        console.error('Error fetching dashboard statistics:', error);
      }
    };

    fetchDashboardStatistics();
  }, []);

  if (!statistics)
    return <div> İstatistikler çekilirken hata oluştu. </div>;

    return (
      <Row gutter={[16, 16]} justify="center">
        <Col xs={20} sm={12} md={8} lg={6} xl={8}>
          <Card style={{ textAlign: 'center', backgroundColor: '#f0f2f5', borderRadius: 8, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Title level={4} style={{ color: '#333' }}>Aktif Site Sayısı</Title>
            <Text style={{ fontSize: 24, color: '#1890ff' }}>{statistics.activeSiteCount}</Text>
          </Card>
        </Col>
        <Col xs={20} sm={12} md={8} lg={6} xl={8}>
          <Card style={{ textAlign: 'center', backgroundColor: '#f0f2f5', borderRadius: 8, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Title level={4} style={{ color: '#333' }}>Aktif Ziyaretçi Sayısı</Title>
            <Text style={{ fontSize: 24, color: '#52c41a' }}>{statistics.activeVisitorCount}</Text>
          </Card>
        </Col>
        <Col xs={20} sm={12} md={8} lg={6} xl={8}>
          <Card style={{ textAlign: 'center', backgroundColor: '#f0f2f5', borderRadius: 8, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Title level={4} style={{ color: '#333' }}>Bugün Ziyaretçi Sayısı</Title>
            <Text style={{ fontSize: 24, color: '#faad14' }}>{statistics.todayVisitorCount}</Text>
          </Card>
        </Col>
        <Col xs={20} sm={12} md={8} lg={6} xl={12}>
          <Card style={{ textAlign: 'center', backgroundColor: '#f0f2f5', borderRadius: 8, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Title level={4} style={{ color: '#333' }}>WhatsApp İletişim</Title>
            <Text style={{ fontSize: 24, color: '#f5222d' }}>{statistics.whatsappContactCount}</Text>
          </Card>
        </Col>
        <Col xs={20} sm={12} md={8} lg={6} xl={12}>
          <Card style={{ textAlign: 'center', backgroundColor: '#f0f2f5', borderRadius: 8, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Title level={4} style={{ color: '#333' }}>Satış Adedi</Title>
            <Text style={{ fontSize: 24, color: '#722ed1' }}>{statistics.saleCount}</Text>
          </Card>
        </Col>
      </Row>
    );
};

export default DashboardPage;

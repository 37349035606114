import { Button, Form, Input, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import ContentService from '../../../api/ContentService';
import { Link, useNavigate, useParams } from 'react-router-dom/dist';
import { useForm } from 'antd/es/form/Form';
import { UploadOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const MenuDetail = () => {
    const [menu, setMenu] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [editorHtml, setEditorHtml] = useState('');

    const contentService = new ContentService();
    const { id } = useParams();
    const [form] = useForm();
    const { TextArea } = Input;
    const navigate = useNavigate();
    const fetchMenu = async () => {
        try {
            setLoading(true);
            const menuData = await contentService.getMenu(id);
            setMenu(menuData.model);
            form.setFieldsValue(menuData.model);
            setLoading(false);
        } catch (error) {
            console.error('Menu verisi alınamadı:', error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id > 0)
            fetchMenu();
    }, [])

    const handleFileChange = (info) => {
        let fileList = [...info.fileList];
        // Dosya yüklemesi sırasında dosya durumlarını kontrol etmek için gerekirse burada işlemler yapılabilir.
        setFileList(fileList);
    };
    const handleEditorChange = (newState) => {
        setEditorHtml(newState);
    };
    const uploadProps = {
        beforeUpload: () => false,
        fileList,
        onChange: handleFileChange,
    };
    const handleOk = async (values) => {
        try {
            if (menu) {
                values.id = menu.id;
                await contentService.updateMenu(values);
                message.success('Menu güncellendi.');
                await fetchMenu({ id });
            } else {
                var resp = await contentService.insertMenu(values);
                await fetchMenu(resp.model);
                message.success('Yeni Menu oluşturuldu.');
                navigate('/Menus');
            }
        } catch (error) {
            message.error('Menu işlemleri sırasında bir hata oluştu.');
        }
    };

    return (
        <Form
            name="MenuForm"
            onFinish={handleOk}
            onFinishFailed={(errorInfo) => console.log('Failed:', errorInfo)}
            initialValues={menu}
            form={form}
            preserve={false}
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 24 }}
            labelAlign='left'
        >
            <Form.Item
                label="Ad"
                name="name"
                rules={[{ required: true, message: 'Lütfen Menu adını girin.' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label="Kod"
                name="code"
                rules={[{ required: true, message: 'Lütfen Menu kodunu girin.' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                wrapperCol={{ offset: 20, span: 18 }} // Submit butonunu sola yasla
            >
                <Button type="primary" htmlType="submit">Kaydet</Button>
            </Form.Item>
        </Form>
    )
}

export default MenuDetail;
import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, Form, Input, message, Upload } from 'antd';
import ContentService from '../../../api/ContentService';
import { DeleteOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom/dist';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const WidgetItems = () => {
  const [widgetItems, setWidgetItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [editorHtml, setEditorHtml] = useState('');
  const [editWidgetItem, setEditWidgetItem] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const contentService = new ContentService();
  const { id } = useParams();

  console.log('id is : ', id);
  useEffect(() => {
    fetchWidgetItems()
  }, []);
  const fetchWidgetItems = async () => {
    try {
      setLoading(true);
      const widgetItemData = await contentService.getWidgetItems(id);
      setWidgetItems(widgetItemData.model);
      setLoading(false);
    } catch (error) {
      console.error('Widget Item verileri alınamadı:', error.message);
      setLoading(false);
    }
  };
  const handleEditorChange = (newState) => {
    setEditorHtml(newState);
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Kod',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Başlık',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => handleEdit(record)}><EditOutlined /></a>
          <a onClick={() => showDeleteConfirm(record.id)}><DeleteOutlined /></a>
        </Space>
      ),
    },
  ];
  const handleFileChange = (info) => {
    let fileList = [...info.fileList];
    // Dosya yüklemesi sırasında dosya durumlarını kontrol etmek için gerekirse burada işlemler yapılabilir.
    setFileList(fileList);
  };
  const uploadProps = {
    beforeUpload: () => false,
    fileList,
    onChange: handleFileChange,
  };
  const showModal = () => {
    form.resetFields();
    setEditWidgetItem(null);
    setVisible(true);
  };
  const handleEdit = (site) => {
    form.resetFields();
    setEditWidgetItem(site);
    setVisible(true);
  };
  useEffect(() => {
    if (editWidgetItem) {
      form.setFieldsValue(editWidgetItem)
    }
  }, [editWidgetItem])
  useEffect(() => {
    if (editWidgetItem) {
      form.setFieldsValue(editWidgetItem)
    }
    else
      form.setFieldValue(null)
  }, [visible])

  
  const handleOk = async (values) => {
    try {
      values.widgetID = id;
      if (editWidgetItem) {
        values.id = editWidgetItem.id;
        const formData = new FormData();
        Object.keys(values).forEach((key) => formData.append(key, values[key]));
        if (fileList.length > 0) {
          fileList.forEach((file) => {
            formData.append('files', file.originFileObj);
          });
        }
        await contentService.updateWidgetItem(formData);
        message.success('Widget Item güncellendi.');
        await fetchWidgetItems();
        setVisible(false);
      }
      else {
        const formData = new FormData();
        Object.keys(values).forEach((key) => formData.append(key, values[key]));
        if (fileList.length > 0) {
          fileList.forEach((file) => {
            formData.append('files', file.originFileObj);
          });
        }
        await contentService.insertWidgetItem(formData);
        await fetchWidgetItems();
        message.success('Yeni widget item oluşturuldu.');
        setVisible(false);
      }
    } catch (error) {
      message.error('Yeni widget item oluşturulamadı.');
    }
  };
  const handleCancel = () => {
    setVisible(false);
    setEditWidgetItem(null);
    form.resetFields();
  };

  const showDeleteConfirm = (siteId) => {
    Modal.confirm({
      title: 'Widget Item sil',
      content: 'Widget Item silmek istediğinizden emin misiniz?',
      okText: 'Sil',
      okType: 'danger',
      cancelText: 'İptal',
      onOk() {
        handleDelete(siteId);
      },
    });
  };
  const handleDelete = async (widgetItemId) => {
    try {
      await contentService.deleteWidgetItem(widgetItemId);
      message.success('Widget Item silindi.');
      await fetchWidgetItems();
    } catch (error) {
      message.error('Widget Item silinemedi.');
    }
  };
  return (
    <div>
      <Button type="primary" style={{ marginBottom: 16 }} onClick={showModal}>
        Yeni Widget Item Oluştur
      </Button>
      <Table
        columns={columns}
        dataSource={widgetItems}
        loading={loading}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title={editWidgetItem ? 'Widget Item Düzenle' : 'Yeni Widget Item Oluştur'}
        open={visible}
        onCancel={handleCancel}
        onOk={form.submit}
        okText={editWidgetItem ? 'Güncelle' : 'Oluştur'}
        cancelText="İptal"
        destroyOnClose={true}
        width="65%"
        maxWidth="80%"
      >
        <Form
          name="newWidgetItemForm"
          onFinish={handleOk}
          onFinishFailed={(errorInfo) => console.log('Failed:', errorInfo)}
          initialValues={editWidgetItem}
          form={form}
          preserve={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="Kod"
            name="code"
            rules={[{ required: true, message: 'Lütfen widget item kodunu girin.' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Başlık"
            name="title"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Alt Başlık"
            name="subtitle"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Background Color"
            name="backgroundColor"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Icon"
            name="Icon"
          >
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <ReactQuill
              theme="snow"
              value={editorHtml}
              onChange={handleEditorChange}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 18 }} label="ImagePath">
            <div style={{ display: 'flex' }}>
              <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Dosya Seç</Button>
              </Upload>
              {editWidgetItem && editWidgetItem.imagePath ?
                <div style={{ marginLeft: '16px' }}>
                  <Link to={editWidgetItem.imagePath} target='_blank'>{editWidgetItem.imagePath.substr(editWidgetItem.imagePath.lastIndexOf("\\") + 1)}</Link> </div> : null}
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default WidgetItems;

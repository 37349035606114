import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import MembershipService from '../api/MembershipService';
import { Outlet, useNavigate } from 'react-router-dom/dist';
const LoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const membershipService = new MembershipService();
  const handleLogin = async (values) => {
    setLoading(true);
    try {
      // API ile giriş bilgilerini kontrol et
      const response = await membershipService.login(values);
      // Giriş başarılı ise kullanıcıyı ana sayfaya yönlendir
      if (response.model?.token) {
        localStorage.setItem('token', response.model.token);
        setUser(response.model);
        navigate('/');
      } else {
        message.error('Kullanıcı adı veya şifre hatalı!');
      }
    } catch (error) {
      console.log('error is : ', error);
      message.error('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: '400px', margin: '0 auto', paddingTop: '100px' }}>
      <Outlet context={{ user, setUser, handleLogin }} />

      <h1 style={{ textAlign: 'center', marginBottom: '30px', fontSize: '28px' }}>Tec10 Console</h1> {/* Bu satır eklendi */}
      <Form
        name="login-form"
        initialValues={{ remember: true }}
        onFinish={handleLogin}
      >
        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Geçerli bir email adresi giriniz.', type:'email' }]}
        >
          <Input placeholder="Kullanıcı Adı" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Şifre zorunlu!' }]}
        >
          <Input.Password placeholder="Şifre" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Giriş Yap
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;

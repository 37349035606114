import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, Form, Input, message, Select } from 'antd';
import ContentService from '../../../api/ContentService';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom/dist';
import { Option } from 'antd/es/mentions';
import { render } from '@testing-library/react';

const SitePages = () => {
  const [sitePages, setSitePages] = useState([]);
  const [pages, setPages] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);

  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const contentService = new ContentService();
  useEffect(() => {
    fetchSitePages()
  }, []);

  const handlePageChange = (value) => {
    setSelectedPage(value);
  };

  const handlePageSelect = async () => {
    await fetchPages();
  };

  const fetchSitePages = async () => {
    try {
      setLoading(true);
      const sitePageData = await contentService.getSitePages({ siteId: id });
      setSitePages(sitePageData.model);
      setLoading(false);
    } catch (error) {
      message.error('Sayfa verileri alınamadı:');
      setLoading(false);
    }
  };

  const fetchPages = async () => {
    try {
      const response = await contentService.getPages({});
      if (response.model) {
        response.model.map(page => ({
          id: id,
          name: page.name, // Burada alt nesnenin özelliğine doğrudan erişiyoruz
        }));
        setPages(response.model);
      }
    } catch (error) {
      message.error('Error fetching pages');
    }
  };
  const formattedSitePages = sitePages.map(page => ({
    id: id,
    name: page.name, // Burada alt nesnenin özelliğine doğrudan erişiyoruz
  }));
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Ad',
      dataIndex: 'pageName',
      key: 'pageName',
      render: (text, record) => <span style={{ cursor: "pointer" }} onClick={() => navigate("/pageDetail/" + record.page.id)}>{record.page.name}</span>
    },
    {
      title: 'Kod',
      dataIndex: 'pageCode',
      key: 'pageCode',
      render: (text, record) => <span style={{ cursor: "pointer" }} onClick={() => navigate("/pageDetail/" + record.page.id)}>{record.page.name}</span>
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => showDeleteConfirm(record.id)}><DeleteOutlined /></a>
        </Space>
      ),
    },
  ];
  const showModal = () => {
    form.resetFields();
    setVisible(true);
  };
  const handleOk = async (values) => {
    try {

      await contentService.insertSitePage({ pageID: selectedPage, siteID: id });
      await fetchSitePages();
      message.success('Yeni sayfa eklendi.');
      setVisible(false);
    } catch (error) {
      message.error('Yeni sayfa eklenemedi.');
    }
  };
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const showDeleteConfirm = (sitePageID) => {
    Modal.confirm({
      title: 'Sayfayı sil',
      content: 'Sayfayı silmek istediğinizden emin misiniz?',
      okText: 'Sil',
      okType: 'danger',
      cancelText: 'İptal',
      onOk() {
        handleDelete(sitePageID);
      },
    });
  };
  const handleDelete = async (sitePageID) => {
    try {
      await contentService.deleteSitePage(sitePageID);
      message.success('Sayfa silindi.');
      await fetchSitePages();
    } catch (error) {
      message.error('Sayfa silinemedi.');
    }
  };
  return (
    <div>
      <Button type="primary" style={{ marginBottom: 16 }} onClick={showModal}>
        Yeni Sayfa Ekle
      </Button>
      <Table
        columns={columns}
        dataSource={sitePages}
        loading={loading}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title={'Yeni Sayfa Ekle'}
        open={visible}
        onCancel={handleCancel}
        onOk={form.submit}
        okText={'Ekle'}
        cancelText="İptal"
        destroyOnClose={true}
        width="65%"
        maxWidth="80%"
      >
        <Form
          name="newSitePageForm"
          onFinish={handleOk}
          onFinishFailed={(errorInfo) => console.log('Failed:', errorInfo)}
          form={form}
          preserve={false}
        >
          <Select onChange={handlePageChange} onClick={handlePageSelect} value={selectedPage} style={{ width: '100%' }}
          >
            {pages.map((page) => (
              <Option key={page.id} value={page.id}>
                {page.name}
              </Option>
            ))}
          </Select>
        </Form>
      </Modal>
    </div>
  );
};

export default SitePages;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ContentService from '../../../api/ContentService';
import { message } from 'antd';
import { useParams } from 'react-router-dom/dist';

const SiteDetail = () => {
    const [site, setSite] = useState(null);
    const contentService = new ContentService();
    const { id } = useParams();
    useEffect(() => {
        fetchSite();
    }, [])

    const fetchSite = async () => {
        const resp = await contentService.getSite(id);
        if (resp.model) {
            setSite(resp.model);
        }
        else {
            message.error("Site detayı çekilemedi");
        }
    }

    if (!site) {
        return <p>Site bulunamadı.</p>;
    }

    return (
        <div>
            <h2>Site Detayları</h2>
            <p><strong>Site Adı:</strong> {site.name}</p>
            <p><strong>Site Kodu:</strong> {site.code}</p>
            <p><strong>Domain:</strong> {site.webAddress}</p>
            {/* Diğer site detaylarını burada gösterebilirsiniz */}
        </div>
    );
};

export default SiteDetail;
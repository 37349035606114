import axios from "axios";
import { useNavigate } from "react-router-dom/dist";

export default class BaseService {
  constructor() {
    this.apiURL = "https://tec10api.soon.it/api/";
  }
  postObject(schemaName, funcName, entity) {
    var url = this.apiURL + schemaName + "/" + funcName;
    return this.callServiceMethod(url, entity, "POST");
  }
  getObject(schemaName, funcName, data) {
    var url =
      this.apiURL + schemaName + "/" + funcName + "/" + (data ? data : "");

    return this.callServiceMethod(url, data, "GET");
  }
  deleteObject(schemaName, funcName, entity) {
    var url = this.apiURL + schemaName + "/" + funcName + "/" + entity;
    return this.callServiceMethod(url, null, "DELETE");
  }

  callServiceMethod(url, data, method) {
    const token = localStorage.getItem('token');
    var headers = {
      'Access-Control-Allow-Origin': 'https://157.245.75.182:9050/',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization': `Bearer ${token}`
    };
    return this.callAnyServiceMethod(url, data, method, headers);
  }
  async callAnyServiceMethod(url, data, method, headers) {
    var authOptions = {
      method,
      url,
      data,
      headers,
    };
    return await axios(authOptions)
      .then((response) => {
        if (response.headers.rt)
          localStorage.setItem('token', 'Bearer ' + response.headers.rt)
        
        console.log('url is : ' , url);
        console.log('request data is : ' , data);
        console.log('response is : ' , response);
        return response.data;
      })
      .catch((error) => {
          localStorage.removeItem('token');
          localStorage.removeItem('isLoggedIn');
          window.location.href = '/';
      });
  }

}

import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, Form, Input, message, Select } from 'antd';
import ContentService from '../../../api/ContentService';
import { DeleteOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom/dist';
import { Option } from 'antd/es/mentions';

const PageWidgets = () => {
  const [pageWidgets, setPageWidgets] = useState([]);
  const [widgets, setWidgets] = useState([]);

  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { id } = useParams();
  const [selectedWidget, setSelectedWidget] = useState(null);

  const contentService = new ContentService();
  useEffect(() => {
    fetchPageWidgets()
  }, []);

  const handleWidgetChange = (value) => {
    setSelectedWidget(value);
  };

  const handleWidgetSelect = async () => {
    await fetchWidgets();
  };

  const fetchPageWidgets = async () => {
    try {
      setLoading(true);
      const pageWidgetData = await contentService.getPageWidgets(id);
      setPageWidgets(pageWidgetData.model);
      setLoading(false);
    } catch (error) {
      message.error('Widget verileri alınamadı:');
      setLoading(false);
    }
  };

  const fetchWidgets = async () => {
    try {
      const response = await contentService.getWidgets({});
      if (response.model) {
        response.model.map(widget => ({
          id: id,
          name: widget.name, // Burada alt nesnenin özelliğine doğrudan erişiyoruz
        }));
        setWidgets(response.model);
      }
    } catch (error) {
      message.error('Error fetching widgets');
    }
  };
  
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Ad',
      dataIndex: 'widgetName',
      key: 'widgetName',
      render: (text, record) => <span>{record.widget?.name}</span>
    },
    {
      title: 'Kod',
      dataIndex: 'widgetCode',
      key: 'widgetCode',
      render: (text, record) => <span>{record.widget?.name}</span>
    },
    {
      title: 'İşlem',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => showDeleteConfirm(record.id)}><DeleteOutlined /></a>
        </Space>
      ),
    },
  ];
  const showModal = () => {
    form.resetFields();
    setVisible(true);
  };
  const handleOk = async (values) => {
    try {

      await contentService.insertPageWidget({ widgetID: selectedWidget, pageID: id });
      await fetchPageWidgets();
      message.success('Yeni widget eklendi.');
      setVisible(false);
    } catch (error) {
      message.error('Yeni widget eklenemedi.');
    }
  };
  const handleCancel = () => {
    setVisible(false);
    form.resetFields();
  };

  const showDeleteConfirm = (pageWidgetID) => {
    Modal.confirm({
      title: 'Widgetı sil',
      content: 'Widgetı silmek istediğinizden emin misiniz?',
      okText: 'Sil',
      okType: 'danger',
      cancelText: 'İptal',
      onOk() {
        handleDelete(pageWidgetID);
      },
    });
  };
  const handleDelete = async (pageWidgetID) => {
    try {
      await contentService.deletePageWidget(pageWidgetID);
      message.success('Widget silindi.');
      await fetchPageWidgets();
    } catch (error) {
      message.error('Widget silinemedi.');
    }
  };
  return (
    <div>
      <Button type="primary" style={{ marginBottom: 16 }} onClick={showModal}>
        Yeni Widget Ekle
      </Button>
      <Table
        columns={columns}
        dataSource={pageWidgets}
        loading={loading}
        rowKey="id"
        bordered
        pagination={{ pageSize: 10 }}
      />
      <Modal
        title={'Yeni Widget Ekle'}
        open={visible}
        onCancel={handleCancel}
        onOk={form.submit}
        okText={'Ekle'}
        cancelText="İptal"
        destroyOnClose={true}
        width="65%" 
        maxWidth="80%"
      >
        <Form
          name="newPageWidgetForm"
          onFinish={handleOk}
          onFinishFailed={(errorInfo) => console.log('Failed:', errorInfo)}
          form={form}
          preserve={false}
        >
          <Select onChange={handleWidgetChange} onClick={handleWidgetSelect} value={selectedWidget} style={{ width: '100%' }}
          >
            {widgets.map((widget) => (
              <Option key={widget.id} value={widget.id}>
                {widget.name}
              </Option>
            ))}
          </Select>
        </Form>
      </Modal>
    </div>
  );
};

export default PageWidgets;

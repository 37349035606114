import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, Form, Input, message } from 'antd';
import ContentService from '../../../api/ContentService';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom/dist';

const Pages = () => {
    const [pages, setPages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [editPage, setEditPage] = useState(null);
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const contentService = new ContentService();
    useEffect(() => {
        fetchPages()
    }, []);

    const fetchPages = async () => {
        try {
            setLoading(true);
            const pageData = await contentService.getPages({});
            setPages(pageData.model);
            setLoading(false);
        } catch (error) {
            console.error('Sayfa verileri alınamadı:', error.message);
            setLoading(false);
        }
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <span onClick={() => navigate(`/pageDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <span onClick={() => navigate(`/pageDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'Kod',
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => <span onClick={() => navigate(`/pageDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'Head Title',
            dataIndex: 'headTitle',
            key: 'headTitle',
            render: (text, record) => <span onClick={() => navigate(`/pageDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'Tagler',
            dataIndex: 'tags',
            key: 'tags',
            render: (text, record) => <span onClick={() => navigate(`/pageDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => handleEdit(record)}><EditOutlined /></a>
                    <a onClick={() => showDeleteConfirm(record.id)}><DeleteOutlined /></a>
                </Space>
            ),
        },
    ];
    const showModal = () => {
        form.resetFields();
        setVisible(true);
    };
    const handleEdit = (page) => {
        setEditPage(page);
        setVisible(true);
    };
    const handleOk = async (values) => {
        try {
            if (editPage) {
                values.id = editPage.id;
                await contentService.updatePage(values);
                message.success('Sayfa güncellendi.');
                await fetchPages();
                setVisible(false);
            }
            else {
                await contentService.insertPage(values);
                await fetchPages();
                message.success('Yeni sayfa oluşturuldu.');
                setVisible(false);
            }
        } catch (error) {
            message.error('Yeni sayfa oluşturulamadı.');
        }
    };
    const handleCancel = () => {
        setVisible(false);
        setEditPage(null);
        form.resetFields();
    };

    const showDeleteConfirm = (pageId) => {
        Modal.confirm({
            title: 'Sayfayı sil',
            content: 'Sayfayı silmek istediğinizden emin misiniz?',
            okText: 'Sil',
            okType: 'danger',
            cancelText: 'İptal',
            onOk() {
                handleDelete(pageId);
            },
        });
    };
    const handleDelete = async (pageId) => {
        try {
            await contentService.deletePage(pageId);
            message.success('Sayfa silindi.');
            await fetchPages();
        } catch (error) {
            message.error('Sayfa silinemedi.');
        }
    };
    return (
        <div>
            <Button type="primary" style={{ marginBottom: 16 }} onClick={showModal}>
                Yeni Sayfa Oluştur
            </Button>
            <Table
                columns={columns}
                dataSource={pages}
                loading={loading}
                rowKey="id"
                bordered
                pagination={{ pageSize: 10 }}
            />
            <Modal
                title={editPage ? 'Sayfayı Düzenle' : 'Yeni Sayfa Oluştur'}
                open={visible}
                onCancel={handleCancel}
                onOk={form.submit}
                okText={editPage ? 'Güncelle' : 'Oluştur'}
                cancelText="İptal"
                destroyOnClose={true}
                width="65%"
                maxWidth="80%"
            >
                <Form
                    name="newPageForm"
                    onFinish={handleOk}
                    onFinishFailed={(errorInfo) => console.log('Failed:', errorInfo)}
                    initialValues={editPage}
                    form={form}
                    preserve={false}
                >
                    <Form.Item
                        label="Ad"
                        name="name"
                        rules={[{ required: true, message: 'Lütfen sayfa adını girin.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Kod"
                        name="code"
                        rules={[{ required: true, message: 'Lütfen sayfa kodunu girin.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Head Title"
                        name="headTitle"
                        rules={[
                            { required: true, message: 'Lütfen sayfa başlığını  girin.' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Tags"
                        name="tags"
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default Pages;

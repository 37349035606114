import React from 'react';
import router from './Router';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import DashboardPage from './pages/Dashboard';
import LoginPage from './pages/Login';
import PrivateRoute from './components/PrivateRoute';
import UsersPage from './pages/Users';
import Sites from './pages/Content/Site/Sites';
import SiteDetail from './pages/Content/Site/SiteDetail';
import SiteTabs from './pages/Content/Site/SiteTabs';
import Pages from './pages/Content/Page/Pages';
import PageTabs from './pages/Content/Page/PageTabs';
import Widgets from './pages/Content/Widget/Widgets';
import WidgetTabs from './pages/Content/Widget/WidgetTabs';
import MenuTabs from './pages/Content/Menu/MenuTabs';
import Menus from './pages/Content/Menu/Menus';
import ParametersPage from './pages/Configuration/Parameters';
import BlogPostsPage from './pages/Content/BlogPost/BlogPosts';


function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        <Route exact path='/' element={<PrivateRoute />}>
          <Route exact path='/' element={<DashboardPage />} />
          <Route exact path='/Users' element={<UsersPage />} />
          
          <Route exact path='/Sites' element={<Sites />} />
          <Route exact path='/SiteDetail/:id' element={<SiteTabs />} />
          
          <Route exact path='/Pages' element={<Pages />} />
          <Route exact path='/PageDetail/:id' element={<PageTabs />} />
          
          <Route exact path='/Widgets' element={<Widgets />} />
          <Route exact path='/WidgetDetail/:id' element={<WidgetTabs />} />

          <Route exact path='/Menus' element={<Menus />} />
          <Route exact path='/MenuDetail/:id' element={<MenuTabs />} />

          <Route exact path='/Parameters' element={<ParametersPage />} />
          <Route exact path='/BlogPosts' element={<BlogPostsPage />} />

        </Route>

      </Routes>
    </BrowserRouter>
  );
}


export default App;

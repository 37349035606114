import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

const LogoutButton = () => {
    const navigate = useNavigate();

    const handleLogout = () => {
        // LocalStorage'dan kullanıcı oturum bilgilerini sil
        localStorage.removeItem('isLoggedIn');
        localStorage.removeItem('token');
        // Login sayfasına yönlendir
        navigate('/login');
    };

    return (
        <Button type="link" onClick={handleLogout} style={{fontSize:'24px'}}>
            <LogoutOutlined style={{fontSize : '24px', color : '#fff'}}/>
        </Button>
    );
};

export default LogoutButton;

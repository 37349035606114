import BaseService from "./BaseService";

export default class ContentService extends BaseService {
  constructor() {
    super();
    this.schemaName = "Content";
  }
  /* Site */
  getSites(entity) {
    return this.postObject(this.schemaName, "GetSites", entity);
  }
  getSite(entity) {
    return this.getObject(this.schemaName, "GetSite", entity);
  }
  insertSite(entity) {
    return this.postObject(this.schemaName, "InsertSite", entity);
  }
  updateSite(entity) {
    return this.postObject(this.schemaName, "UpdateSite", entity);
  }
  deleteSite(entity) {
    return this.deleteObject(this.schemaName, "DeleteSite", entity);
  }

  /* BlogPost */
  getBlogPosts(entity) {
    return this.postObject(this.schemaName, "GetBlogPosts", entity);
  }
  getBlogPost(entity) {
    return this.getObject(this.schemaName, "GetBlogPost", entity);
  }
  insertBlogPost(entity) {
    return this.postObject(this.schemaName, "InsertBlogPost", entity);
  }
  updateBlogPost(entity) {
    return this.postObject(this.schemaName, "UpdateBlogPost", entity);
  }
  deleteBlogPost(entity) {
    return this.deleteObject(this.schemaName, "DeleteBlogPost", entity);
  }

  /* Page */

  getPages(entity) {
    return this.postObject(this.schemaName, "GetPages", entity);
  }
  getPage(entity) {
    return this.getObject(this.schemaName, "GetPage", entity);
  }
  insertPage(entity) {
    return this.postObject(this.schemaName, "InsertPage", entity);
  }
  updatePage(entity) {
    return this.postObject(this.schemaName, "UpdatePage", entity);
  }
  deletePage(entity) {
    return this.deleteObject(this.schemaName, "DeletePage", entity);
  }

  /* Site Page */
  getSitePages(entity) {
    return this.postObject(this.schemaName, "GetSitePages", entity);
  }
  insertSitePage(entity) {
    return this.postObject(this.schemaName, "InsertSitePage", entity);
  }
  deleteSitePage(entity) {
    return this.deleteObject(this.schemaName, "DeleteSitePage", entity);
  }

  /* Widgets */

  getWidgets(entity) {
    return this.postObject(this.schemaName, "GetWidgets", entity);
  }
  getWidget(entity) {
    return this.getObject(this.schemaName, "GetWidget", entity);
  }
  insertWidget(entity) {
    return this.postObject(this.schemaName, "InsertWidget", entity);
  }
  updateWidget(entity) {
    return this.postObject(this.schemaName, "UpdateWidget", entity);
  }
  deleteWidget(entity) {
    return this.deleteObject(this.schemaName, "DeleteWidget", entity);
  }

  /* Widget Items */
  getWidgetItems(entity) {
    return this.getObject(this.schemaName, "GetWidgetItems", entity);
  }
  getWidgetItem(entity) {
    return this.getObject(this.schemaName, "GetWidgetItem", entity);
  }
  insertWidgetItem(entity) {
    return this.postObject(this.schemaName, "InsertWidgetItem", entity);
  }
  updateWidgetItem(entity) {
    return this.postObject(this.schemaName, "UpdateWidgetItem", entity);
  }
  deleteWidgetItem(entity) {
    return this.deleteObject(this.schemaName, "DeleteWidgetItem", entity);
  }

  /* PageWidgets */
  getPageWidgets(entity) {
    return this.getObject(this.schemaName, "GetPageWidgets", entity);
  }
  getPageWidget(entity) {
    return this.getObject(this.schemaName, "GetPageWidget", entity);
  }
  insertPageWidget(entity) {
    return this.postObject(this.schemaName, "InsertPageWidget", entity);
  }
  updatePageWidget(entity) {
    return this.postObject(this.schemaName, "UpdatePageWidget", entity);
  }
  deletePageWidget(entity) {
    return this.deleteObject(this.schemaName, "DeletePageWidget", entity);
  }

  /* Menu */

  getMenus(entity) {
    return this.postObject(this.schemaName, "GetMenus", entity);
  }
  getMenu(entity) {
    return this.getObject(this.schemaName, "GetMenu", entity);
  }
  insertMenu(entity) {
    return this.postObject(this.schemaName, "InsertMenu", entity);
  }
  updateMenu(entity) {
    return this.postObject(this.schemaName, "UpdateMenu", entity);
  }
  deleteMenu(entity) {
    return this.deleteObject(this.schemaName, "DeleteMenu", entity);
  }

  /* MenuItem */

  getMenuItems(entity) {
    return this.getObject(this.schemaName, "GetMenuItems", entity);
  }
  getMenuItem(entity) {
    return this.getObject(this.schemaName, "GetMenuItem", entity);
  }
  insertMenuItem(entity) {
    return this.postObject(this.schemaName, "InsertMenuItem", entity);
  }
  updateMenuItem(entity) {
    return this.postObject(this.schemaName, "UpdateMenuItem", entity);
  }
  deleteMenuItem(entity) {
    return this.deleteObject(this.schemaName, "DeleteMenuItem", entity);
  }
}

import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, Form, Input, message } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import ContentService from '../../../api/ContentService';
import ReactQuill from 'react-quill';

const BlogPostsPage = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [editBlogPost, setEditBlogPost] = useState(null);
    const [editorHtml, setEditorHtml] = useState('');

    const [form] = Form.useForm();

    const contentService = new ContentService();
    useEffect(() => {
        fetchBlogPosts()
    }, []);

    const fetchBlogPosts = async () => {
        try {
            setLoading(true);
            const blogPostData = await contentService.getBlogPosts({});
            setBlogPosts(blogPostData.model);
            setLoading(false);
        } catch (error) {
            console.error('BlogPost verileri alınamadı:', error.message);
            setLoading(false);
        }
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Başlık',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => handleEdit(record)}><EditOutlined /></a>
                    <a onClick={() => showDeleteConfirm(record.id)}><DeleteOutlined /></a>
                </Space>
            ),
        },
    ];
    const showModal = () => {
        form.resetFields();
        setVisible(true);
    };
    const handleEdit = (blogPost) => {
        setEditBlogPost(blogPost);
        setVisible(true);
    };
    const handleOk = async (values) => {
        try {
            if (editBlogPost) {
                values.id = editBlogPost.id;
                await contentService.updateBlogPost(values);
                message.success('BlogPost güncellendi.');
                await fetchBlogPosts();
                setVisible(false);
            }
            else {
                await contentService.insertBlogPost(values);
                await fetchBlogPosts();
                message.success('Yeni blogpost oluşturuldu.');
                setVisible(false);
            }
        } catch (error) {
            console.error('Yeni blogpost oluşturulamadı:', error.message);
            message.error('Yeni blogpost oluşturulamadı.');
        }
    };
    const handleCancel = () => {
        setVisible(false);
        setEditBlogPost(null);
        form.resetFields();
    };

    const showDeleteConfirm = (blogPostId) => {
        Modal.confirm({
            title: 'BlogPost sil',
            content: 'BlogPost silmek istediğinizden emin misiniz?',
            okText: 'Sil',
            okType: 'danger',
            cancelText: 'İptal',
            onOk() {
                handleDelete(blogPostId);
            },
        });
    };
    const handleDelete = async (blogPostId) => {
        try {
            await contentService.deleteBlogPost(blogPostId);
            message.success('BlogPost silindi.');
            await fetchBlogPosts();
        } catch (error) {
            message.error('BlogPost silinemedi.');
        }
    };
    const handleEditorChange = (newState) => {
        setEditorHtml(newState);
    }
    return (
        <div>
            <Button type="primary" style={{ marginBottom: 16 }} onClick={showModal}>
                Yeni BlogPost Oluştur
            </Button>
            <Table
                columns={columns}
                dataSource={blogPosts}
                loading={loading}
                rowKey="id"
                bordered
                pagination={{ pageSize: 10 }}
            />
            <Modal
                title={editBlogPost ? 'BlogPost Düzenle' : 'Yeni BlogPost Oluştur'}
                open={visible}
                onCancel={handleCancel}
                onOk={form.submit}
                okText={editBlogPost ? 'Güncelle' : 'Oluştur'}
                cancelText="İptal"
                destroyOnClose={true}
            >
                <Form
                    name="newBlogPostForm"
                    onFinish={handleOk}
                    onFinishFailed={(errorInfo) => console.log('Failed:', errorInfo)}
                    initialValues={editBlogPost}
                    form={form}
                    preserve={false}
                >
                    <Form.Item
                        label="Başlık"
                        name="title"
                        rules={[{ required: true, message: 'Lütfen başlık girin.' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                        <ReactQuill
                            theme="snow"
                            value={editorHtml}
                            onChange={handleEditorChange}
                        />
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    );
};

export default BlogPostsPage;

import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, Form, Input, message } from 'antd';
import ContentService from '../../../api/ContentService';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom/dist';

const Menus = () => {
    const [menus, setMenus] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const contentService = new ContentService();
    useEffect(() => {
        fetchMenus()
    }, []);

    const fetchMenus = async () => {
        try {
            setLoading(true);
            const menuData = await contentService.getMenus({});
            setMenus(menuData.model);
            setLoading(false);
        } catch (error) {
            message.error('Menu verileri alınamadı:');
            setLoading(false);
        }
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <span onClick={() => navigate(`/menuDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <span onClick={() => navigate(`/menuDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'Kod',
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => <span onClick={() => navigate(`/menuDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => showDeleteConfirm(record.id)}><DeleteOutlined /></a>
                </Space>
            ),
        },
    ];
    const showDeleteConfirm = (menuId) => {
        Modal.confirm({
            title: 'Menüyü sil',
            content: 'Menüyü silmek istediğinizden emin misiniz?',
            okText: 'Sil',
            okType: 'danger',
            cancelText: 'İptal',
            onOk() {
                handleDelete(menuId);
            },
        });
    };
    const handleDelete = async (menuId) => {
        try {
            await contentService.deleteMenu(menuId);
            message.success('Menğ silindi.');
            await fetchMenus();
        } catch (error) {
            message.error('Menü silinemedi.');
        }
    };
    return (
        <div>
            <Button type="primary" style={{ marginBottom: 16 }} onClick={() => navigate('/menuDetail/0')}            >
                Yeni Menu Oluştur
            </Button>
            <Table
                columns={columns}
                dataSource={menus}
                loading={loading}
                rowKey="id"
                bordered
                pagination={{ pageSize: 10 }}
            />
        </div>
    );
};

export default Menus;

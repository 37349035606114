import BaseService from "./BaseService";

export default class ConfigurationService extends BaseService {
  constructor() {
    super();
    this.schemaName = "Configuration";
  }
  getDashboardStatistics(entity) {
    return this.getObject(this.schemaName, "GetDashboardStatistics", entity);
  }

  /* Parameter */
  getParameters(entity) {
    return this.postObject(this.schemaName, "GetParameters", entity);
  }
  getParameter(entity) {
    return this.getObject(this.schemaName, "GetParameter", entity);
  }
  insertParameter(entity) {
    return this.postObject(this.schemaName, "InsertParameter", entity);
  }
  updateParameter(entity) {
    return this.postObject(this.schemaName, "UpdateParameter", entity);
  }
  deleteParameter(entity) {
    return this.deleteObject(this.schemaName, "DeleteParameter", entity);
  }

}

import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Modal, Form, Input, message } from 'antd';
import ContentService from '../../../api/ContentService';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom/dist';

const Widgets = () => {
    const [widgets, setwidgets] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const contentService = new ContentService();
    useEffect(() => {
        fetchWidgets()
    }, []);

    const fetchWidgets = async () => {
        try {
            setLoading(true);
            const widgetData = await contentService.getWidgets({});
            setwidgets(widgetData.model);
            setLoading(false);
        } catch (error) {
            message.error('Widget verileri alınamadı:');
            setLoading(false);
        }
    };
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => <span onClick={() => navigate(`/widgetDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'Ad',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => <span onClick={() => navigate(`/widgetDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'Kod',
            dataIndex: 'code',
            key: 'code',
            render: (text, record) => <span onClick={() => navigate(`/widgetDetail/${record.id}`)} style={{ cursor: 'pointer' }}>{text}</span>,
        },
        {
            title: 'İşlem',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a onClick={() => showDeleteConfirm(record.id)}><DeleteOutlined /></a>
                </Space>
            ),
        },
    ];
    const showDeleteConfirm = (widgetId) => {
        Modal.confirm({
            title: 'Siteyi sil',
            content: 'Siteyi silmek istediğinizden emin misiniz?',
            okText: 'Sil',
            okType: 'danger',
            cancelText: 'İptal',
            onOk() {
                handleDelete(widgetId);
            },
        });
    };
    const handleDelete = async (widgetId) => {
        try {
            await contentService.deleteWidget(widgetId);
            message.success('Widget silindi.');
            await fetchWidgets();
        } catch (error) {
            message.error('Widget silinemedi.');
        }
    };
    return (
        <div>
            <Button type="primary" style={{ marginBottom: 16 }} onClick={() => navigate('/widgetDetail/0')}            >
                Yeni Widget Oluştur
            </Button>
            <Table
                columns={columns}
                dataSource={widgets}
                loading={loading}
                rowKey="id"
                bordered
                pagination={{ pageSize: 10 }}
            />
        </div>
    );
};

export default Widgets;
